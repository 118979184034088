import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid } from '@mui/material';
import emailjs from 'emailjs-com';

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const templateID = process.env.REACT_APP_TEMPLATE_ID;
    const userID = process.env.REACT_APP_USER_ID;

    const templateParams = {
      name,
      email,
      message,
      subject: `New Contact Form Submission from ${name}`,
    };

    emailjs
      .send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
        setSuccessMessage('Your message has been sent successfully!');
        setErrorMessage('');
        // Clear the form fields
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        setErrorMessage(
          'There was an error sending your message. Please try again later.'
        );
        setSuccessMessage('');
      });
  };

  return (
    <Box sx={{ mt: 8, textAlign: 'center' }}>
      <Typography variant="h3" fontWeight="bold">
        Contact Us
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
        Have a question or want to get in touch? Fill out the form below, and
        we’ll get back to you shortly.
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ maxWidth: 600, mx: 'auto' }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Full Name"
              variant="outlined"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email Address"
              type="email"
              variant="outlined"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Message"
              variant="outlined"
              multiline
              rows={4}
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="success"
              size="large"
              fullWidth
              type="submit"
            >
              Send Message
            </Button>
          </Grid>
        </Grid>
        {successMessage && (
          <Typography color="green">{successMessage}</Typography>
        )}
        {errorMessage && <Typography color="red">{errorMessage}</Typography>}
      </Box>

      {/* Contact Details */}
      <Box sx={{ mt: 8 }}>
        <Typography variant="h5" fontWeight="bold">
          Contact Details
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          <strong>Address:</strong> Dauphinelaan, Eindhoven, The Netherlands
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          <strong>WhatsApp:</strong>{' '}
          <a
            href="https://wa.me/31614975966"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: 'green',
              fontWeight: 'bold',
            }}
          >
            +31 614 975 966
          </a>
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          <strong>Opening Hours:</strong>
        </Typography>
        <Typography variant="body1">
          Monday - Friday: 9:00 AM - 5:00 PM
        </Typography>
        <Typography variant="body1">Saturday: 10:00 AM - 4:00 PM</Typography>
        <Typography variant="body1">Sunday: Closed</Typography>
        <Typography variant="body1">Chamber of Commerce: 83679898</Typography>
      </Box>
    </Box>
  );
};

export default ContactUs;
