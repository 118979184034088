import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { CssBaseline, Container } from '@mui/material';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import InnovationSection from './components/InnovationSection';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Solutions from './components/Solutions';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';
import Box from '@mui/material/Box';

function App() {
  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Navbar />
        <Box sx={{ flexGrow: 1 }}>
          <Container>
            <Routes>
              <Route path="/" element={<HeroSection />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/solutions" element={<Solutions />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/portfolio" element={<Portfolio />} />
            </Routes>
            <InnovationSection />
          </Container>
        </Box>
        <Footer />
      </Box>
    </>
  );
}

export default App;
